import styled from "../theme/styled"

const DefaultButton = styled.button(props => ({
	fontFamily: props.theme.font.family.heading,
	fontWeight: 600,
	textTransform: `uppercase`,
	textDecoration: `none`,
	fontSize: props.theme.font.size.m,
	padding: `.2em 2em`,
	lineHeight: `1em`,
	borderWidth: `2px`,
	borderStyle: `solid`,
	borderColor: props.theme.colours.primary,
	color: props.theme.colours.on.primary,
	background: props.theme.colours.primary,
	cursor: `pointer`,
	"&:disabled": {
		background: props.theme.colours.tones.neutral,
		borderColor: props.theme.colours.tones.neutral,
		cursor: "not-allowed",
	},
	"&:hover:not(:disabled)": {
		transition: `all 0.25s ease`,
		background: props.theme.colours.inverted,
		color: props.theme.colours.on.inverted,
	},
}))

const InvertedButton = styled(DefaultButton)(props => ({
	borderColor: props.theme.colours.on.primary,
	color: props.theme.colours.primary,
	background: props.theme.colours.on.primary,
	"&:hover:not(:disabled)": {
		transition: `all 0.25s ease`,
		borderColor: props.theme.colours.on.inverted,
		background: props.theme.colours.on.inverted,
		color: props.theme.colours.inverted,
	},
}))

export { DefaultButton, InvertedButton }

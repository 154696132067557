import styled from "../theme/styled"

const H1 = styled.h1(props => ({
	color: props.theme.colours.primary,
	fontWeight: 600,
	fontSize: `48px`,
	fontFamily: props.theme.font.family.heading,
	textTransform: `uppercase`,
}))

const H2 = styled.h2(props => ({
	color: props.theme.colours.primary,
	fontWeight: 600,
	fontSize: `36px`,
	fontFamily: props.theme.font.family.heading,
	textTransform: `uppercase`,
}))

const H3 = styled.h3(props => ({
	color: props.theme.colours.primary,
	fontWeight: 400,
	fontSize: `24px`,
	fontFamily: props.theme.font.family.heading,
}))

const H4 = styled.h4(props => ({
	color: props.theme.colours.primary,
	fontWeight: 600,
	fontSize: `24px`,
	fontFamily: props.theme.font.family.heading,
	textTransform: `uppercase`,
}))

const H5 = styled.h5(props => ({
	color: props.theme.colours.primary,
	fontWeight: 600,
	fontSize: `16px`,
	fontFamily: props.theme.font.family.heading,
	textTransform: `uppercase`,
}))

export { H1, H2, H3, H4, H5 }

import styled from "../theme/styled"
import mq from "../theme/mq"

const Container = styled.div(props => ({
	maxWidth: `1440px`,
	margin: `0 auto`,
	padding: `0 ${props.theme.spacing.xl}`,
	[mq.container]: {
		padding: `0`,
	},
}))

export { Container }
